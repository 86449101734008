/* eslint-disable import/no-duplicates */
/* eslint-disable indent */
/* eslint-disable eol-last */
/**
 * @description      :
 * @author           : AbigaelHOMENYA
 * @group            :
 * @created          : 04/05/2023 - 17:51:33
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/05/2023
 * - Author          : AbigaelHOMENYA
 * - Modification    :
 **/
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// ...............................................................................

import './assets/css/styles.css'
import './assets/css/plugins/animation.css'
import './assets/css/plugins/bootstrap.min.css'
import './assets/css/plugins/slick.css'
import './assets/css/plugins/slick-theme.css'
import './assets/css/plugins/dropzone.css'
import './assets/css/plugins/magnificpopup.css'
import './assets/css/plugins/date-picker.css'
import './assets/css/plugins/themify.css'
import './assets/css/plugins/line-icons.css'
import './assets/css/plugins/iconfont.css'
import './assets/css/plugins/font-awesome.css'
import './assets/css/plugins/flaticon.css'

// ............................................................

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// ........................................................................................
import { BCollapse } from 'bootstrap-vue'
Vue.config.productionTip = false

new Vue({
    router,
    store,
    BCollapse,
    render: (h) => h(App)
}).$mount('#app')