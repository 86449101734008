<template>
<div id="main-wrapper">
      <div class="clearfix"></div>

      <!-- ======================= Home Banner ======================== style="background:#f41b3b url(https://via.placeholder.com/1920x950) no-repeat;"-->
      <div class="home-banner margin-bottom-0" data-overlay="5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-11 col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="banner_caption text-center mb-5">
                <h1 class="banner_title ft-bold mb-1">
                  Bienvenue sur la plateforme des Innovateurs et du
                  développement durable
                </h1>
                <p class="fs-md ft-medium">
                  Découvrez de merveilleux innovations et créer un compte pour
                  en savoir plus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ======================= Home Banner ======================== -->

      <!-- ======================= Listing Categories ======================== -->
      <section class="space min gray">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="sec_title position-relative text-center mb-5">
                <h6 class="mb-0 theme-cl">Cathegories d'innovations</h6>
                <h2 class="ft-bold">Parcourir les catégories</h2>
              </div>
            </div>
          </div>

          <!-- row -->
          <div class="row align-items-center">
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
              <div class="cats-wrap text-center">
                <a href="/listcategorie" class="Goodup-catg-wrap">
                  <!-- <div class="Goodup-catg-city">07 Cities</div> -->
                  <div class="Goodup-catg-icon">
                    <i class="fas fa-stethoscope"></i>
                  </div>
                  <div class="Goodup-catg-caption">
                    <h4 class="fs-md mb-0 ft-medium m-catrio">Agricole</h4>
                    <span class="text-muted">607 listes</span>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
              <div class="cats-wrap text-center">
                <a href="/listcategorie" class="Goodup-catg-wrap">
                  <!-- <div class="Goodup-catg-city">17 Cities</div> -->
                  <div class="Goodup-catg-icon">
                    <i class="fas fa-building"></i>
                  </div>
                  <div class="Goodup-catg-caption">
                    <h4 class="fs-md mb-0 ft-medium m-catrio">
                      Technologique &amp; Banque
                    </h4>
                    <span class="text-muted">76 Lites</span>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
              <div class="cats-wrap text-center">
                <a href="/listcategorie" class="Goodup-catg-wrap">
                  <!-- <div class="Goodup-catg-city">26 Cities</div> -->
                  <div class="Goodup-catg-icon">
                    <i class="fas fa-utensils"></i>
                  </div>
                  <div class="Goodup-catg-caption">
                    <h4 class="fs-md mb-0 ft-medium m-catrio">Restauration</h4>
                    <span class="text-muted">172 Listes</span>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
              <div class="cats-wrap text-center">
                <a href="/listcategorie" class="Goodup-catg-wrap">
                  <!-- <div class="Goodup-catg-city">10 Cities</div> -->
                  <div class="Goodup-catg-icon">
                    <i class="fas fa-book-open"></i>
                  </div>
                  <div class="Goodup-catg-caption">
                    <h4 class="fs-md mb-0 ft-medium m-catrio">Education</h4>
                    <span class="text-muted">144 Listes</span>
                  </div>
                </a>
              </div>
            </div>

            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
              <div class="cats-wrap text-center">
                <a href="/listcategorie" class="Goodup-catg-wrap">
                  <!-- <div class="Goodup-catg-city">06 Cities</div> -->
                  <div class="Goodup-catg-icon">
                    <i class="fas fa-car-alt"></i>
                  </div>
                  <div class="Goodup-catg-caption">
                    <h4 class="fs-md mb-0 ft-medium m-catrio">Automobile</h4>
                    <span class="text-muted">52 Listes</span>
                  </div>
                </a>
              </div>
            </div>

        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6">
          <div class="cats-wrap text-center">
            <a href="/listcategorie" class="Goodup-catg-wrap">
              <!-- <div class="Goodup-catg-city">06 Cities</div> -->
              <div class="Goodup-catg-icon">
                <i class="fas fa-car-alt"></i>
              </div>
              <div class="Goodup-catg-caption">
                <h4 class="fs-md mb-0 ft-medium m-catrio">Robotique</h4>
                <span class="text-muted">52 Listes</span>
              </div>
            </a>
          </div>
        </div>
          </div>
          <!-- row -->
        </div>
    </section>
      <!-- ======================= Listing Categories End ======================== -->

      <!-- ======================= Our Partner Start ============================ -->
      <section class="pt-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-7 col-md-9 col-sm-12">
              <div class="sec_title position-relative text-center mb-5">
                <h6 class="text-muted mb-0 mt-2">Nos Partenaires</h6>
                <h2 class="ft-bold">
                  Nous travaillons
                  <span class="theme-cl">avec plusieurs</span> Entreprises de confiance
                </h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="empl-thumb text-center px-3 py-4">
                <img
                  src="../assets/img/images/logo_denosterres.png"
                  class="img-fluid mx-auto"
                  alt=""
                />
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="empl-thumb text-center px-3 py-4">
                <img
                  src="../assets/img/images/logo.png"
                  class="img-fluid mx-auto"
                  alt=""
                />
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="empl-thumb text-center px-3 py-4">
                <img
                  src="../assets/img/images/fis-logo-green.jpg"
                  class="img-fluid mx-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======================= Our Partner Start ============================ -->

      <!-- ======================= Blog Start ============================ -->
      <section class="space min pt-0">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="sec_title position-relative text-center mb-4">
                <h6 class="theme-cl mb-0">Dernières publications</h6>
                <h2 class="ft-bold">Nouvelles mises à jour</h2>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <!-- Single Item -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div class="gup_blg_grid_box">
                <div class="gup_blg_grid_thumb">
                  <a href="blog-detail.html"
                    ><img
                      src="../assets/img/images/cuisine_robot.jpg"
                      class="img-fluid"
                      alt=""
                  /></a>
                </div>
                <div class="gup_blg_grid_caption">
                  <div class="blg_tag"><span>Robotique</span></div>
                  <div class="blg_title">
                    <h4>
                      <a href="blog-detail.html"
                        >Quels sont les puissances du robot?</a
                      >
                    </h4>
                  </div>
                  <div class="blg_desc">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum
                    </p>
                  </div>
                </div>
                <div class="crs_grid_foot">
                  <div
                    class="crs_flex d-flex align-items-center justify-content-between br-top px-3 py-2"
                  >
                    <div class="crs_fl_first">
                      <div class="crs_tutor">
                        <div class="crs_tutor_thumb">
                          <a href="javascript:void(0);"
                            ><img
                              src="https://via.placeholder.com/400x400"
                              class="img-fluid circle"
                              width="35"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                    <div class="crs_fl_last">
                      <div class="foot_list_info">
                        <ul>
                          <li>
                            <div class="elsio_ic">
                              <i class="fa fa-eye text-success"></i>
                            </div>
                            <div class="elsio_tx">10k vue</div>
                          </li>
                          <li>
                            <div class="elsio_ic">
                              <i class="fa fa-clock text-warning"></i>
                            </div>
                            <div class="elsio_tx">10 July 2021</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Single Item -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div class="gup_blg_grid_box">
                <div class="gup_blg_grid_thumb">
                  <a href="blog-detail.html"
                    ><img
                      src="../assets/img/images/satelite.jpg"
                      class="img-fluid"
                      alt=""
                  /></a>
                </div>
                <div class="gup_blg_grid_caption">
                  <div class="blg_tag"><span>Agricole</span></div>
                  <div class="blg_title">
                    <h4>
                      <a href="blog-detail.html"
                        >Comment fonctionnent ces satelites?</a
                      >
                    </h4>
                  </div>
                  <div class="blg_desc">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum
                    </p>
                  </div>
                </div>
                <div class="crs_grid_foot">
                  <div
                    class="crs_flex d-flex align-items-center justify-content-between br-top px-3 py-2"
                  >
                    <div class="crs_fl_first">
                      <div class="crs_tutor">
                        <div class="crs_tutor_thumb">
                          <a href="javascript:void(0);"
                            ><img
                              src="https://via.placeholder.com/400x400"
                              class="img-fluid circle"
                              width="35"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                    <div class="crs_fl_last">
                      <div class="foot_list_info">
                        <ul>
                          <li>
                            <div class="elsio_ic">
                              <i class="fa fa-eye text-success"></i>
                            </div>
                            <div class="elsio_tx">10k Vues</div>
                          </li>
                          <li>
                            <div class="elsio_ic">
                              <i class="fa fa-clock text-warning"></i>
                            </div>
                            <div class="elsio_tx">10 July 2021</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Single Item -->
            <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div class="gup_blg_grid_box">
                <div class="gup_blg_grid_thumb">
                  <a href="blog-detail.html"
                    ><img
                      src="../assets/img/images/charge_vehicule.jpg"
                      class="img-fluid"
                      alt=""
                  /></a>
                </div>
                <div class="gup_blg_grid_caption">
                  <div class="blg_tag"><span>Automobile</span></div>
                  <div class="blg_title">
                    <h4>
                      <a href="blog-detail.html"
                        >Charge ton véhicule en moins de 5 min</a
                      >
                    </h4>
                  </div>
                  <div class="blg_desc">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum
                    </p>
                  </div>
                </div>
                <div class="crs_grid_foot">
                  <div
                    class="crs_flex d-flex align-items-center justify-content-between br-top px-3 py-2"
                  >
                    <div class="crs_fl_first">
                      <div class="crs_tutor">
                        <div class="crs_tutor_thumb">
                          <a href="javascript:void(0);"
                            ><img
                              src="https://via.placeholder.com/400x400"
                              class="img-fluid circle"
                              width="35"
                              alt=""
                          /></a>
                        </div>
                      </div>
                    </div>
                    <div class="crs_fl_last">
                      <div class="foot_list_info">
                        <ul>
                          <li>
                            <div class="elsio_ic">
                              <i class="fa fa-eye text-success"></i>
                            </div>
                            <div class="elsio_tx">10k Vues</div>
                          </li>
                          <li>
                            <div class="elsio_ic">
                              <i class="fa fa-clock text-warning"></i>
                            </div>
                            <div class="elsio_tx">10 July 2021</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ======================= Blog Start ============================ -->

      <!-- ========================== Download App Section =============================== -->
      <section>
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-lg-6 col-md-12 col-sm-12 content-column">
              <div class="content_block_2 pr-3 py-4">
                <div class="content-box">
                  <div class="sec-title light">
                    <p
                      class="theme-cl px-3 py-1 rounded bg-light-danger d-inline-flex"
                    >
                      Téléchargez l'application mobile
                    </p>
                    <h2 class="ft-bold">
                      Téléchargez l'application mobile<br />pour une utilisation
                      plus facile
                    </h2>
                  </div>
                  <div class="text mb-3">
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                      qui blanditiis praesentium voluptatum deleniti atque
                      corrupti quos dolores et quas molestias excepturi sint
                      occaecati cupiditate non provident, similique sunt in
                      culpa qui officia deserunt mollitia animi, id est laborum
                      et dolorum fuga.
                    </p>
                  </div>
                  <div class="position-relative row">
                    <div class="col-lg-4 col-md-4 col-4">
                      <h3 class="ft-bold theme-cl mb-0">
                        <span class="count">10</span>k+
                      </h3>
                      <p class="ft-medium">Active Jobs</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-4">
                      <h3 class="ft-bold theme-cl mb-0">
                        <span class="count">12</span>k+
                      </h3>
                      <p class="ft-medium">Resumes</p>
                    </div>
                    <div class="col-lg-4 col-md-4 col-4">
                      <h3 class="ft-bold theme-cl mb-0">
                        <span class="count">07</span>k+
                      </h3>
                      <p class="ft-medium">Abonnés</p>
                    </div>
                  </div>
                  <div class="btn-box clearfix mt-5">
                    <a
                      href="index.html"
                      class="download-btn play-store me-1 d-inline-flex"
                      ><img src="../assets/img/ios.png" width="200" alt=""
                    /></a>
                    <a
                      href="index.html"
                      class="download-btn play-store ms-2 mb-1 d-inline-flex"
                      ><img src="../assets/img/and.png" width="200" alt=""
                    /></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-5 col-md-12 col-sm-12 image-column">
              <div class="image-box">
                <figure class="image">
                  <img src="../assets/img/app.png" class="img-fluid" alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ========================== Download App Section =============================== -->

      <div
        class="modal fade"
        id="login"
        tabindex="-1"
        role="dialog"
        aria-labelledby="loginmodal"
        aria-hidden="true"
      >
        <div class="modal-dialog login-pop-form" role="document">
          <div class="modal-content" id="loginmodal">
            <div class="modal-headers">
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span class="ti-close"></span>
              </button>
            </div>

            <div class="modal-body p-5">
              <div class="text-center mb-4">
                <h4 class="m-0 ft-medium">Login Your Account</h4>
              </div>

              <form class="submit-form">
                <div class="form-group">
                  <label class="mb-1">User Name</label>
                  <input
                    type="text"
                    class="form-control rounded bg-light"
                    placeholder="Username*"
                  />
                </div>

                <div class="form-group">
                  <label class="mb-1">Password</label>
                  <input
                    type="password"
                    class="form-control rounded bg-light"
                    placeholder="Password*"
                  />
                </div>

                <div class="form-group">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-1">
                      <input
                        id="dd"
                        class="checkbox-custom"
                        name="dd"
                        type="checkbox"
                        checked
                      />
                      <label for="dd" class="checkbox-custom-label"
                        >Remember Me</label
                      >
                    </div>
                    <div class="eltio_k2">
                      <a href="#" class="theme-cl">Lost Your Password?</a>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-md full-width theme-bg text-light rounded ft-medium"
                  >
                    Sign In
                  </button>
                </div>

                <div class="form-group text-center mb-0">
                  <p class="extra">Or login with</p>
                  <div class="option-log">
                    <div class="single-log-opt">
                      <a href="javascript:void(0);" class="log-btn"
                        ><img
                          src="https://via.placeholder.com/200x200"
                          class="img-fluid"
                          alt=""
                        />Login with Google</a
                      >
                    </div>
                    <div class="single-log-opt">
                      <a href="javascript:void(0);" class="log-btn"
                        ><img
                          src="assets/img/facebook.png"
                          class="img-fluid"
                          alt=""
                        />Login with Facebook</a
                      >
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->

      <a id="tops-button" class="top-scroll" title="Back to top" href="#"
        ><i class="ti-arrow-up"></i></a>
    </div>
</template>

<script>
export default {
  name: 'HomePage'

}
</script>
