<template>
  <div id="app">
     <HeaderPage />
     <router-view />
     <HeadPage />
     <NewsLettersPage />
     <FooterPage />
  </div>
</template>

<script>
import HeaderPage from '@/components/Header'
import HeadPage from '@/components/headerFooter/main-head'
import FooterPage from '@/components/headerFooter/main-footer'
import NewsLettersPage from '@/components/newsletters.vue'

export default {
  name: 'app',
  components: {
    HeaderPage,
    HeadPage,
    NewsLettersPage,
    FooterPage
  }
}
</script>

<style>

</style>
