/* eslint-disable eol-last */
/* eslint-disable indent */
/**
 * @description      :
 * @author           : AbigaelHOMENYA
 * @group            :
 * @created          : 10/05/2023 - 12:40:32
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 10/05/2023
 * - Author          : AbigaelHOMENYA
 * - Modification    :
 **/
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},

    getters: {},

    mutations: {},

    actions: {},

    modules: {}
})