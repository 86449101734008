<template>
  <main>
    <!-- ============================ Footer Start ================================== -->
    <footer class="light-footer skin-light-footer style-2">
      <div class="footer-middle">
        <div class="container">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div class="footer_widget">
                <img
                  src="../../assets/img/images/innov.png"
                  class="img-footer small mb-2"
                  alt=""
                />

                <div class="address mt-2">
                 Cote d'Ivoire, Abidjan, Cocody <br />Angré
                </div>
                <div class="address mt-3">
                  +225 40 568 423 6597<br />support@innovdur.com
                </div>
                <div class="address mt-2">
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a href="#" class="theme-cl"
                        ><i class="lni lni-facebook-filled"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#" class="theme-cl"
                        ><i class="lni lni-twitter-filled"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#" class="theme-cl"
                        ><i class="lni lni-youtube"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#" class="theme-cl"
                        ><i class="lni lni-instagram-filled"></i
                      ></a>
                    </li>
                    <li class="list-inline-item">
                      <a href="#" class="theme-cl"
                        ><i class="lni lni-linkedin-original"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <div class="footer_widget">
                <h4 class="widget_title">Menu principal</h4>
                <ul class="footer-menu">
                  <li><a href="/about">A propros</a></li>
                  <li><a href="/innovation">Innovations</a></li>
                  <li><a href="/faq">Faq</a></li>
                  <li><a href="/contact">Contact</a></li>
                  <li><a href="#"></a></li>
                </ul>
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <div class="footer_widget">
                <h4 class="widget_title">Connexion</h4>
                <ul class="footer-menu">
                  <li><a href="/inscription">Connexion</a></li>
                  <li><a href="/connexion">S'inscrire</a></li>
                  <li><a href="#"></a></li>
                  <li><a href="#"></a></li>
                </ul>
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <div class="footer_widget">
                <h4 class="widget_title">Innovdurable</h4>
                <ul class="footer-menu">
                  <li><a href="/about">Qui sommes-nous?</a></li>
                  <li><a href="/about">Notre Mission</a></li>
                  <li><a href="/about">Notre équipe</a></li>
                  <!-- <li><a href="#">Packages</a></li>
                  <li><a href="#">Dashboard</a></li> -->
                </ul>
              </div>
            </div>

            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12">
              <div class="footer_widget">
                <h4 class="widget_title">Autres</h4>
                <ul class="footer-menu">
                  <!-- <li><a href="#">Site Map</a></li> -->
                  <li><a href="#">Security</a></li>
                  <li><a href="#">Contact</a></li>
                  <li><a href="#">FAQ's Page</a></li>
                  <!-- <li><a href="#">Privacy</a></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-bottom br-top">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
              <p class="mb-0">
                © 2023 Innov'Durable. Created By <a href="#">MoonLoop</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- ============================ Footer End ================================== -->
  </main>
</template>

<script>
export default {
  name: 'FooterPage',
  props: {}
}
</script>
