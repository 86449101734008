<template>
  <!-- Start Navigation -->
  <div class="header header-light dark-text">
    <div class="container">
      <nav id="navigation" class="navigation navigation-landscape">
        <div class="nav-header">
          <a class="nav-brand" href="#">
            <img src="../assets/img/images/innov.png" class="logo" alt="" />
          </a>
          <div class="nav-toggle"></div>
          <div class="mobile_nav">
            <ul>
              <li>
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#login"
                  class="theme-cl fs-lg"
                >
                  <i class="lni lni-user"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="nav-menus-wrapper" style="transition-property: none">
          <ul class="nav-menu">
            <li class="active">
              <router-link to="/">Accueil</router-link>
            </li>

            <li>
              <a href="/about">A propos de Nous</a>
              <ul class="nav-dropdown nav-submenu"></ul>
            </li>
            <li>
              <a href="/innovation">Innovations</a>
              <ul class="nav-dropdown nav-submenu"></ul>
            </li>
            <li>
              <a href="/faq">Faq</a>
              <ul class="nav-dropdown nav-submenu"></ul>
            </li>
            <!-- <li>
              <a href="#">User Dashboard</a>
              <ul class="nav-dropdown nav-submenu">
                <li>
                  <a href="/dashboard"
                    ><i class="lni lni-dashboard me-2"></i>Tableau de bord</a
                  >
                </li>
                <li>
                  <a href="/listing"
                    ><i class="lni lni-files me-2"></i>Mes Innovations en ligne</a
                  >
                </li>
                <li>
                  <a href="/addinnovation"
                    ><i class="lni lni-add-files me-2"></i>Ajout innovation</a
                  >
                </li>

                <li>
                  <a href="/message"
                    ><i class="lni lni-envelope me-2"></i>Messages<span
                      class="count-tag bg-sky"
                      >4</span
                    ></a
                  >
                </li>
                <li>
                  <a href="#"
                    ><i class="lni lni-user me-2"></i>Mon Profil
                  </a>
                </li>
                <li>
                  <a href="/changepassword"
                    ><i class="lni lni-lock-alt me-2"></i>Changer mot de passe</a
                  >
                </li>
              </ul>
            </li> -->
            <li>
              <a
                href="/contact"
                data-bs-toggle="modal"
                data-bs-target="#login"
                class="ft-bold"
                >Contact</a
              >
            </li>
          </ul>

          <ul class="nav-menu nav-menu-social align-to-right">
            <li>
              <a
                href="/inscription"
                data-bs-toggle="modal"
                data-bs-target="#login"
                class="ft-bold"
              >
                <i class="fas fa-sign-in-alt me-1 theme-cl"></i>S'inscrire
              </a>
            </li>
            <li>
              <a
                href="/connexion"
                data-bs-toggle="modal"
                data-bs-target="#login"
                class="ft-bold"
              >
                <i class="fas fa-sign-in-alt me-1 theme-cl"></i>Se connecter
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <!-- End Navigation -->
</template>

<script>
export default {
  name: 'HeaderPage',
  props: {}
}
</script>
