<!-- eslint-disable eol-last -->
<!-- eslint-disable eol-last -->
<template>
    <main>
        <head>
            <!-- Meta Data -->
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <title>Innov'Durable plateforme des innovateurs</title>
            <!-- Favicon -->
            <link rel="shortcut icon" type="image/x-icon" href="../../assets/img/images/innov.png">
            <!-- Custom CSS -->
            <link href="assets/css/styles.css" rel="stylesheet">
         </head>
    </main>
</template>

<script>
export default {
  name: 'HeadPage'
}
// eslint-disable-next-line eol-last
</script>