/* eslint-disable func-call-spacing */
/* eslint-disable space-in-parens */
/* eslint-disable eol-last */
/* eslint-disable indent */
/**
 * @description      :
 * @author           : AbigaelHOMENYA
 * @group            :
 * @created          : 04/05/2023 - 17:18:22
 *
 * MODIFICATION LOG
 * - Version         : 1.0.0
 * - Date            : 04/05/2023
 * - Author          : AbigaelHOMENYA
 * - Modification    :
 **/
// eslint-disable-next-line semi
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// les pages de l'innovateur
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'HomePage',
        component: Home
    },
    {
        path: '/about',
        name: 'AboutPage',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/innovation',
        name: 'InnovationPage',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Innovation.vue')
    },
    {
        path: '/detailInnovation',
        name: 'DetailInnovationPage',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/DetailInnovation.vue')
    },
    {
        path: '/faq',
        name: 'FaqPage',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Faq.vue')
    },
    {
        path: '/listcategorie',
        name: 'ListCategoriePage',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/ListCategorie.vue')
    },
    {
        path: '/contact',
        name: 'ContactPage',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/Contact.vue')
    },
    {
        path: '/inscription',
        name: 'InscriptionPage',
        component: () =>
            import ( /* webpackChunkName: "inscription" */ '../views/Inscription.vue')
    },
    {
        path: '/connexion',
        name: 'ConnexionPage',
        component: () =>
            import ( /* webpackChunkName: "inscription" */ '../views/Connexion.vue')
    },

    // LES ROUTES DE L'ESPACE INNOVATEUR
    {
        path: '/addinnovation',
        name: 'AddInnovationPage',
        component: () =>
            import ( /* webpackChunkName: "inscription" */ '../views/innovateurs/AddInnovation.vue')
    },
    {
        path: '/changepassword',
        name: 'ChangePasswordPage',
        component: () =>
            import ( /* webpackChunkName: "inscription" */ '../views/innovateurs/ChangePassword.vue')
    },
    {
        path: '/dashboard',
        name: 'DashboardPage',
        component: () =>
            import ( /* webpackChunkName: "inscription" */ '../views/innovateurs/Dashboard.vue')
    },
    {
        path: '/listing',
        name: 'ListingPage',
        component: () =>
            import ( /* webpackChunkName: "inscription" */ '../views/innovateurs/Listing.vue')
    },
    {
        path: '/message',
        name: 'MessagePage',
        component: () =>
            import ( /* webpackChunkName: "inscription" */ '../views/innovateurs/Message.vue')
    },
    {
        path: '/profil',
        name: 'ProfilPage',
        component: () =>
            import ( /* webpackChunkName: "inscription" */ '../views/innovateurs/Profil.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router